<template>
  <div>
    <b-input-group
      class="input-group-merge width-custom w-100"
      :class="{ 'is-invalid': hasError }"
    >
      <b-input-group class="select-input">
        <b-form-select
          :value="linked"
          :options="options"
          @input="onChange"
        />
      </b-input-group>
    </b-input-group>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BFormSelect,
  },

  props: {
    errors: {
      type: String,
      default: '',
      required: false,
    },

    hasError: {
      type: Boolean,
      default: false,
      required: false,
    },

    linked: {
      type: String,
      default: '',
      required: false,
    },
  },

  data() {
    return {
      options: [
        {
          text: 'Please select option',
          value: '',
        },
        {
          text: 'Shopify',
          value: 'SHOPIFY',
        },
      ],
    }
  },

  watch: {
    linked(value) {
      this.linked = value
    },
  },

  methods: {
    onChange(data) {
      this.$emit('input', data)
    },
  },
}
</script>
