<template>
  <div>
    <b-input-group
      class="input-group-merge width-custom w-100"
      :class="{ 'is-invalid': hasError }"
    >
      <b-input-group class="select-input">
        <b-form-select
          :value="storeUrl"
          :disabled="isDisabled"
          :options="options"
          @input="onChange"
        />
      </b-input-group>
    </b-input-group>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormSelect,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    BInputGroup,
    BFormSelect,
  },

  props: {
    errors: {
      type: String,
      default: '',
      required: false,
    },

    hasError: {
      type: Boolean,
      default: false,
      required: false,
    },

    storeUrl: {
      type: String,
      default: '',
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDisabled: false,
    }
  },

  computed: {
    ...mapGetters(['user']),

    options() {
      let data = [
        {
          text: 'Please select option',
          value: '',
        },
      ]

      if (this.user.data?.shopify?.stores) {
        const stores = this.user.data?.shopify?.stores.map(store => ({
          text: store.shopUrl,
          value: store.shopUrl,
        }))

        data = [...data, ...stores]
      }

      data = [...data, { text: 'Add new', value: 'add-new' }]
      return data
    },

  },

  watch: {
    storeUrl(value) {
      this.storeUrl = value
    },

    disabled: {
      handler(value) {
        this.isDisabled = value
      },
      immediate: true,
    },
  },

  methods: {
    onChange(data) {
      this.$emit('input', data)
    },
  },
}
</script>
