import { render, staticRenderFns } from "./PixelDetail.vue?vue&type=template&id=1adfbfec&scoped=true&"
import script from "./PixelDetail.vue?vue&type=script&lang=js&"
export * from "./PixelDetail.vue?vue&type=script&lang=js&"
import style0 from "./PixelDetail.vue?vue&type=style&index=0&id=1adfbfec&lang=scss&scoped=true&"
import style1 from "./PixelDetail.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1adfbfec",
  null
  
)

export default component.exports