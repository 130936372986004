<template>
  <div class="header-table">
    <b-alert
      variant="success"
      show
      class="alert-box"
    >
      <feather-icon
        icon="CheckCircleIcon"
        size="18"
      />
      <div class="ml-1">
        <h5>Ecomdy Platform is now supporting multiple pixels!</h5>
        <p>You can now easily add & manage multiple TikTok pixels in your store!</p>
      </div>
    </b-alert>

    <b-alert
      variant="danger"
      show
      class="alert-box"
    >
      <div class="d-flex">
        <feather-icon
          icon="AlertTriangleIcon"
          size="18"
        />
        <div class="ml-1">
          <h5>Turn on Ecomdy Platform's App Embed</h5>
          <p>Ecomdy Platform's App Embed must be toggled on from the theme's setting in order for the app to function. Open your theme settings now and toggle it on. <strong>Don't forget to click on the Save button!</strong></p>
        </div>
      </div>
      <div class="mt-2 ml-2" />
    </b-alert>

    <div class="form-action">
      <div class="wrapper">
        <!-- filter desktop -->
        <b-row class="justify-content-end align-items-center mb-1">
          <b-col class="p-0">
            <h4 class="font-medium mb-0">
              Set up tracking pixel
            </h4>
          </b-col>
        </b-row>

        <b-row>
          <validation-observer
            ref="pixelValidation"
            v-slot="{ invalid }"
            class="w-100"
          >
            <b-form
              @submit.prevent="submit"
            >
              <b-row class="mb-1">
                <b-col class="col-12 col-md-6">
                  <b-tabs>
                    <b-tab :active="tab === 'pixel_id'">
                      <template #title>
                        <span class="tab-title">Pixel ID</span>
                      </template>

                      <b-form-group
                        label=""
                        class="form-label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          id="pixelId"
                          name="Pixel ID"
                          rules="required|equalTwenty"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              v-model="id"
                              name="Pixel id"
                              class="input-height"
                              trim
                              placeholder="Paste your Pixel ID here. Example: CBTH5NJC77UFHQ3J27AG"
                              :tabindex="1"
                              :state="errors.length > 0 ? false : null"
                              @keyup.enter="handleQueryPixel"
                              @change="handleQueryPixel"
                            />
                          </b-input-group>
                          <p
                            v-if="id && generatePixelData.count"
                            class="mt-1 text-green"
                          >
                            Generated: {{ `${generatePixelData.count}/20` }} pixel(s).
                          </p>
                          <small class="text-danger d-block mt-1">{{ (id && queryPixelData.error) || generatePixelData.error || errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-tab>
                    <b-tab
                      :active="tab === 'pixel_code'"
                    >
                      <template #title>
                        <span class="tab-title">Pixel Code</span>
                      </template>

                      <b-form-group
                        label=""
                        class="form-label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          id="pixelCode"
                          name="Pixel Code"
                          rules="validPixelCode"
                        >
                          <b-form-textarea
                            v-model="pixelScript"
                            placeholder="Enter pixel code ..."
                            rows="3"
                            max-rows="6"
                            @keyup.enter="handleQueryPixel"
                            @change="handleQueryPixel"
                          />
                          <small class="text-danger d-block mt-1">{{ errors[0] || (pixelScript && queryPixelData.error) }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-tab>
                  </b-tabs>

                </b-col>
                <b-col class="col-12 col-md-4 space-top">
                  <b-form-group
                    label="Ad Account Id"
                    class="form-label"
                  >
                    <b-input-group
                      class="input-group-merge width-custom w-100"
                    >
                      <b-input-group class="select-input">
                        <b-form-select
                          v-model="adAccountId"
                          :disabled="loading"
                          :options="adsAccounts"
                          @change="handleChooseAdsId"
                        />
                      </b-input-group>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-md-2">
                  <btn-loading
                    class="generate-btn"
                    variant-convert="btn-submit"
                    pill
                    :loading="false"
                    :disabled="!adAccountId || !isExsitAdsId"
                    @click="handleGenerate"
                  >
                    Generate
                  </btn-loading>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col>
                  <b-form-group
                    label="Pixel name"
                    class="form-label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      id="pixelName"
                      name="Pixel name"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model="name"
                          name="Pixel name"
                          class="input-height"
                          disabled
                          placeholder="Name of this pixel"
                          :tabindex="1"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col class="col-6">
                  <b-form-group
                    label="Linked platform"
                    class="form-label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      id="linked"
                      name="linked"
                      rules="required"
                    >
                      <platform-linked
                        :linked="linked"
                        :has-error="errors.length > 0"
                        @input="handleChangeLinked"
                      />

                      <small
                        v-if="errors[0]"
                        class="text-danger d-block"
                        style="margin-top: 10px"
                      >
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="!showNewStoreModal"
                  class="col-6"
                >
                  <b-form-group
                    label="Linked Store"
                    class="form-label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      id="shopUrl"
                      name="Store Url"
                      rules="required"
                    >
                      <store-linked
                        :store-url="shopUrl"
                        :has-error="errors.length > 0"
                        :disabled="!linked"
                        @input="handleChangeShopUrl"
                      />

                      <small
                        v-if="errors[0]"
                        class="text-danger d-block"
                        style="margin-top: 10px"
                      >
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <b-form-checkbox
                    v-model="enableViewContent"
                    class="custom-control-primary"
                    @click="!enableViewContent"
                  >
                    View Content: When a page is viewed.
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <b-form-checkbox
                    v-model="enableAddToCart"
                    class="custom-control-primary"
                    @click="!enableAddToCart"
                  >
                    Add to Cart: When an item is added to the shopping cart.
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <b-form-checkbox
                    v-model="enableInitiateCheckout"
                    class="custom-control-primary"
                    @click="!enableInitiateCheckout"
                  >
                    Initiate Checkout: When the checkout process is started.
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <b-form-checkbox
                    v-model="enableCompletePayment"
                    class="custom-control-primary"
                    @click="!enableCompletePayment"
                  >
                    Complete Payment: When a payment is completed.
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row
                v-if="enableCompletePayment"
                class="mb-2 ml-1"
              >
                <b-col>
                  <b-form-checkbox
                    v-model="enableOrderInformation"
                    class="custom-control-primary"
                    @click="!enableOrderInformation"
                  >
                    Order information: Track the total order’s value, currency and product name
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <b-form-checkbox
                    v-model="enableAutomaticMatching"
                    class="custom-control-primary custom-disabled"
                    disabled
                    @click="!enableAutomaticMatching"
                  >
                    <span class="custom-text">Automatic Advanced Matching</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row
                v-if="enableAutomaticMatching"
                class="mb-2 ml-2"
              >
                <b-col class="col-12 mb-2 d-flex">
                  <b-form-checkbox
                    v-model="enableAutomaticMatchingEmail"
                    class="custom-control-primary custom-disabled"
                    disabled
                    @click="!enableAutomaticMatchingEmail"
                  >
                    <span
                      v-if="!pixelHref"
                      class="custom-text"
                    >
                      Email
                    </span>
                  </b-form-checkbox>
                  <b-link
                    v-if="pixelHref"
                    class="text-black pixel-link"
                    :disabled="!pixelHref"
                    @click="openPixelHref"
                  >
                    Email
                    <feather-icon
                      size="12"
                      icon="Edit2Icon"
                    />
                  </b-link>
                </b-col>

                <b-col class="col-12 d-flex">
                  <b-form-checkbox
                    v-model="enableAutomaticMatchingPhoneNumber"
                    class="custom-control-primary custom-disabled"
                    disabled
                    @click="!enableAutomaticMatchingPhoneNumber"
                  >
                    <span
                      v-if="!pixelHref"
                      class="custom-text"
                    >
                      Phone number
                    </span>
                  </b-form-checkbox>
                  <b-link
                    v-if="pixelHref"
                    class="text-black pixel-link"
                    :disabled="!pixelHref"
                    @click="openPixelHref"
                  >
                    Phone number
                    <feather-icon
                      size="12"
                      icon="Edit2Icon"
                    />
                  </b-link>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col class="d-flex">
                  <b-form-checkbox
                    v-model="enableAllowCookies"
                    class="custom-control-primary custom-disabled"
                    disabled
                    @click="!enableAllowCookies"
                  >
                    <span
                      v-if="!pixelHref"
                      class="custom-text"
                    >
                      Allow first-party cookies
                    </span>
                  </b-form-checkbox>
                  <b-link
                    v-if="pixelHref"
                    class="text-black pixel-link"
                    :disabled="!pixelHref"
                    @click="openPixelHref"
                  >
                    Allow first-party cookies
                    <feather-icon
                      size="12"
                      icon="Edit2Icon"
                    />
                  </b-link>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mt-2">
                  <btn-loading
                    class="mr-1"
                    variant-convert="btn-submit"
                    type="submit"
                    pill
                    :disabled="id.length !== 20 ? true : invalid || !linked || !shopUrl"
                    :loading="checkEnabledEmbed.loading || pixelLoading"
                  >
                    {{ $route.params.id ? 'Update' : 'Add new' }}
                  </btn-loading>

                  <b-button
                    variant="secondary"
                    class="cancel-btn"
                    pill
                    @click.prevent="$router.push('/pixel-management')"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-row>
      </div>
      <b-modal
        v-model="showNewStoreModal"
        centered
        title="Add New Store"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
      >
        <div>
          <b-form-group
            label="Shop URL"
            class="form-label"
          >
            <validation-provider
              #default="{ errors }"
              id="newShopUrl"
              name="New Shop URL"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model="newShopUrl"
                  name="New Shop URL"
                  class="input-height"
                  placeholder="Enter Store URL"
                  :tabindex="1"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger d-block mt-1">{{ errors[0] || addNewStoreUrl.error }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <template #modal-footer>
          <b-button
            variant="secondary"
            @click="hideModal"
          >
            Cancel
          </b-button>

          <b-button
            variant="primary"
            :disabled="!newShopUrl"
            @click="createNewStore"
          >
            OK
          </b-button>
        </template>
      </b-modal>

      <b-modal
        v-model="showConfirmPopup"
        centered
        title="Warning"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
      >
        <div>
          <p>
            This is a demo ad account, if you want to use pixel please complete onboarding to be converted into official ad account
          </p>
        </div>
        <template #modal-footer>
          <b-button
            variant="secondary"
            @click="handleCancelConfirm"
          >
            Cancel
          </b-button>

          <b-button
            variant="primary"
            @click="$router.history.push('/my-ads-account')"
          >
            OK
          </b-button>
        </template>
      </b-modal>

      <!-- Welcome modal -->
      <b-modal
        v-if="!checkEnabledEmbed.loading"
        v-model="showWelcome"
        id="modal-child"
        class="modal-child"
        ok-title="Confirm"
        size="w-add-fund"
        hide-footer
        hide-header
        hide-header-close
        centered
      >
        <!--Content-->
        <div class="d-flex justify-content-between top-modal">
          <h3 class="font-medium text-primary">
            Welcome To Ecomdy Platform!
          </h3>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="showWelcome = false"
            />
          </div>
        </div>

        <!--    content modal-->
        <div>
          <p class="content-welcome">
            In order for Ecomdy Platform to properly work on your OS 2.0 theme,
            you'll have to activate it from the Theme Editor (it's in the top right corner)
            <span class="text-underline font-weight-600">Don't forget to click on the Save button!</span>
          </p>
          <img
            :src="require('@/assets/images/pages/gif-welcome-to-pixel-v2.gif')"
            alt="gif"
            class="w-100"
          >
        </div>

        <!-- modal footer -->
        <div class="d-flex justify-content-end mt-2">
          <b-button
            variant="flat-secondary"
            pill
            @click="showWelcome = false"
          >
            Cancel
          </b-button>
          <btn-loading
            class="ml-1"
            variant-convert="btn-submit"
            type="submit"
            :loading="loading"
            pill
            @click.prevent="handleEnableExtensionPixel"
          >
            Activate Now
          </btn-loading>
        </div>
        <small
          v-if="message"
          class="text-danger d-block mt-1"
        >
          {{ message }}
        </small>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BFormCheckbox,
  BTabs,
  BTab,
  BFormTextarea,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import stores from '@/store'
import { createNamespacedHelpers } from 'vuex'
import _sort from 'lodash/sortBy'
import { required, validPixelCode, equalTwenty } from '@validations'
import PixelMixin from '@/mixins/pixelMixin'
import { toastification } from '@core/mixins/toast'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import PlatformLinked from './components/PlatformLinked.vue'
import StoreLinked from './components/StoreLinked.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('pixel')
const { mapActions: mapActionAdsAccount } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersAdsAccount } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BTabs,
    BTab,
    BAlert,
    BFormTextarea,
    BLink,
    PlatformLinked,
    StoreLinked,
  },
  mixins: [PixelMixin, toastification],

  data() {
    return {
      required,
      validPixelCode,
      equalTwenty,
      newShopUrl: '',
      showNewStoreModal: false,
      id: '',
      name: '',
      linked: '',
      adAccountId: '',
      shopUrl: '',
      enableViewContent: true,
      enableAddToCart: true,
      enableInitiateCheckout: true,
      enableCompletePayment: true,
      enableOrderInformation: true,
      enableAllowCookies: false,
      enableAutomaticMatching: true,
      enableAutomaticMatchingEmail: false,
      enableAutomaticMatchingPhoneNumber: false,
      adsAccounts: [],
      tab: 'pixel_id',
      pixelScript: '',
      showConfirmPopup: false,
      showWelcome: false,
      settingParams: {
        code: '',
        adsAccountId: '',
      },
      pixelHref: '',
    }
  },

  computed: {
    ...mapGetters(['pixelLoading', 'detail', 'message', 'generatePixelData', 'status', 'addNewStoreUrl', 'queryPixelData', 'enableExtension', 'checkEnabledEmbed']),
    ...mapGettersAdsAccount(['listAccount', 'loading']),
    ...mapGettersAuth(['user']),

    disabledSubmit() {
      if (this.id.length !== 20) return true
      return !this.name || !this.id || !this.linked
    },

    isExsitAdsId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.listAccount.content.find(item => item._id === this.adAccountId)
    },

    latestStore() {
      const storeSorted = _sort(this.user.data?.shopify?.stores, store => store.lastUsed)
      if (!storeSorted.length) {
        return ''
      }

      return storeSorted[storeSorted.length - 1].shopUrl
    },

  },

  watch: {
    shopUrl(value) {
      if (value) {
        this.checkEnableEmbedStore({
          shopUrl: value,
        })
      }
    },

    checkEnabledEmbed(value) {
      if (!value.enabled && !value.loading) {
        this.showWelcome = true
      } else {
        this.showWelcome = false
      }
    },

    enableExtension(value) {
      if (value.redirectUrl) {
        window.open(value.redirectUrl)
      }
    },

    queryPixelData(value) {
      if (value.code) {
        this.id = value.code
      }

      if (value.adsAccountId && value.code) {
        this.pixelHref = `https://ads.tiktok.com/i18n/events_manager/pixel/detail/${value.code}?aadvid=${value.adsAccountId}&type=S2SSetting`
      }

      if (value.name) {
        this.name = value.name
      } else {
        this.name = ''
      }

      if (value.script) {
        this.pixelScript = value.script
      }

      if (value.settings) {
        this.enableAutomaticMatchingEmail = value.settings.enableAutomaticMatchingEmail
        this.enableAutomaticMatchingPhoneNumber = value.settings.enableAutomaticMatchingPhoneNumber
        this.enableAllowCookies = value.settings.enableAllowCookies
      }

      // need to check adsId and render to selection
      this.setOutSideId(value.adsAccountId)
      this.adAccountId = value.adsAccountId
    },

    generatePixelData(value) {
      if (value.pixel?.code) {
        this.id = value.pixel.code
      }

      if (value.pixel?.script) {
        this.pixelScript = value.pixel.script
      }

      if (value.pixel?.pixelName) {
        this.name = value.pixel.pixelName
      }

      if (value.pixel?.adsAccountId && value.pixel?.code) {
        this.pixelHref = `https://ads.tiktok.com/i18n/events_manager/pixel/detail/${value.pixel.code}?aadvid=${value.pixel.adsAccountId}&type=S2SSetting`
      }
    },

    enableCompletePayment(value) {
      if (!value) {
        this.enableOrderInformation = false
      } else {
        this.enableOrderInformation = true
      }
    },

    // set data for get detail
    detail(value) {
      const { id } = this.$route.params
      if (value && id) {
        this.name = value.pixelName
        this.linked = value.platform
        this.shopUrl = value.shopUrl
        this.id = value.code
        this.adAccountId = value.adsAccountId
        this.pixelScript = value.script
        this.enableViewContent = value.configs?.enableViewContent
        this.enableAddToCart = value.configs?.enableAddToCart
        this.enableInitiateCheckout = value.configs?.enableInitiateCheckout
        this.enableOrderInformation = value.configs?.enableOrderInformation
        this.enableCompletePayment = value.configs?.enableCompletePayment

        this.enableAutomaticMatchingEmail = value.settings.enableAutomaticMatchingEmail
        this.enableAutomaticMatchingPhoneNumber = value.settings.enableAutomaticMatchingPhoneNumber
        this.enableAllowCookies = value.settings.enableAllowCookies

        this.pixelHref = `https://ads.tiktok.com/i18n/events_manager/pixel/detail/${value.code}?aadvid=${value.adsAccountId}&type=S2SSetting`

        this.setOutSideId(value.adsAccountId)
      } else {
        this.resetDetail()
      }
    },
  },

  created() {
    this.createComponent()
    stores.commit('pixel/RESET_ERROR')
  },

  methods: {
    ...mapActions(['getPixel', 'resetDetail', 'attachPixel', 'queryPixel', 'addNewStore', 'resetNewStore', 'generatePixel', 'checkEnableEmbedStore', 'enableExtensionPixel', 'enabledExtension', 'getRetrieveSettings']),
    ...mapActionAdsAccount(['getAccountList']),

    async createComponent() {
      await this.getAccountList()
      this.initAdsAccountOptions()
      this.handleAccessFromStore()
      this.getDetail()
    },

    async handleEnableExtensionPixel() {
      await this.enabledExtension({
        shopUrl: this.shopUrl,
      })
    },

    handleEnableEmbed() {
      this.enabledExtension({
        shopUrl: this.latestStore,
      })
    },

    setOutSideId(id) {
      if (!id || !this.adsAccounts.length) return

      const isAdsAccountIdExist = this.adsAccounts.find(item => item.value === id)
      if (isAdsAccountIdExist) return

      const filter = this.adsAccounts.filter(item => item.value)
      this.adsAccounts = [
        {
          text: 'Please select option',
          value: '',
        },
        {
          text: `Ads_outside_${id}`,
          value: id,
        },
        ...filter,
      ]
    },

    initAdsAccountOptions() {
      const adsAccountOptions = {
        text: 'Please select option',
        value: '',
      }

      const optionCreateNew = {
        text: 'Create Ad account',
        value: 'create_ads_account',
      }

      if (!this.listAccount.content.length || this.loading) {
        this.adsAccounts = [
          adsAccountOptions,
          optionCreateNew,
        ]
        return
      }

      const adsAccountList = this.listAccount.content.map(item => ({
        text: item.name,
        // eslint-disable-next-line no-underscore-dangle
        value: item._id,
      }))

      this.adsAccounts = [
        adsAccountOptions,
        ...adsAccountList,
        optionCreateNew,
      ]
    },

    handleAccessFromStore() {
      const {
        pixelAdsAccountId,
        pixelCode,
        pixelName,
        shopUrl,
      } = this.$route.query

      if (pixelAdsAccountId) {
        this.adAccountId = pixelAdsAccountId
        this.setOutSideId(pixelAdsAccountId)
      } else {
        this.adAccountId = ''
      }

      if (pixelCode) {
        this.id = pixelCode
      }

      if (pixelName) {
        this.name = pixelName
      }

      if (shopUrl) {
        this.shopUrl = shopUrl
        this.linked = 'SHOPIFY'
      }
    },

    getDetail() {
      const { id } = this.$route.params
      if (id) {
        this.getPixel(id)
      }
    },

    handleChangeLinked(data) {
      this.linked = data
    },

    handleChangeShopUrl(data) {
      if (data === 'add-new') {
        this.showNewStoreModal = true
        return
      }
      this.shopUrl = data
    },

    handleQueryPixel() {
      if (this.id.length === 20) {
        this.queryPixel({
          code: this.id,
          script: this.pixelScript,
        })
      }
    },

    handleChooseAdsId(value) {
      if (value === 'create_ads_account') {
        this.$router.history.push('/my-ads-account?tab=settings')
      }
    },

    handleGenerate() {
      if (!this.adAccountId || !this.isExsitAdsId) return

      // eslint-disable-next-line no-underscore-dangle
      const currentAds = this.listAccount.content.find(item => item._id === this.adAccountId)
      if (currentAds && currentAds.isAdsAccountTrial) {
        this.showConfirmPopup = true
        return
      }

      this.generatePixel({
        adsAccountId: this.adAccountId,
      })
    },

    handleCancelConfirm() {
      this.showConfirmPopup = false
    },

    hideModal() {
      this.newShopUrl = ''
      this.showNewStoreModal = false
      this.resetNewStore()
    },

    async createNewStore() {
      if (this.newShopUrl) {
        await this.addNewStore({
          shopUrl: this.newShopUrl,
          pixelCode: this.id,
          pixelName: this.name,
          adsAccountId: this.adAccountId,
        })
        if (this.status) {
          window.location.href = this.addNewStoreUrl.url
        }
      }
    },

    openPixelHref() {
      window.open(this.pixelHref)
    },

    async submit() {
      let payload = {
        shopUrl: this.shopUrl,
        adsAccountId: this.adAccountId,
        pixelName: this.name,
        platform: this.linked,
        configs: {
          enableViewContent: this.enableViewContent,
          enableAddToCart: this.enableAddToCart,
          enableInitiateCheckout: this.enableInitiateCheckout,
          enableCompletePayment: this.enableCompletePayment,
          enableOrderInformation: this.enableOrderInformation,
        },
        settings: {
          enableAllowCookies: this.enableAllowCookies,
          enableAutomaticMatchingEmail: this.enableAutomaticMatchingEmail,
          enableAutomaticMatchingPhoneNumber: this.enableAutomaticMatchingPhoneNumber,
        },
      }
      if (this.id) {
        payload = {
          ...payload,
          code: this.id,
        }
      }

      if (this.pixelScript) {
        payload = {
          ...payload,
          script: this.pixelScript,
        }
      }
      await this.attachPixel(payload)

      if (this.message) {
        this.toastFailure(`${this.message}`)
      } else {
        this.toastSuccess('Attach code to store success')
      }

      if (this.status) {
        setTimeout(() => {
          // window.location.href = '/pixel-management'
          this.$router.push('/pixel-management')
        },
        2000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 20px;
}
.header-table {
  background-color: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 32px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}
.generate-btn {
  margin-top: 60px;
  background-color: #16213E !important;
}

.cancel-btn {
  padding: 11px 20px;
  font-size: 14px;
}

.space-top {
  margin-top: 30px;
}

.alert-box.alert-success {
  display: flex;
  border: 1px solid;
  background-color: #d4edda !important;
  border-color: #a0d5ad !important;
  padding: 12px 20px !important;
  color: #16213E !important;
  svg {
    min-width: 18px;
  }
}
.alert-box.alert-danger {
  border: 1px solid;
  border-color:#dc3545 !important;
  padding: 12px 20px !important;
  color: #16213E !important;
  svg {
    min-width: 18px;
  }
  h4, strong {
    font-weight: bold;
  }
  strong {
    font-size: 18px;
    color: #e11c4e !important;
  }
}
.pixel-link {
  &:hover {
    text-decoration: underline;
  }
}
.text-black {
  color: #3D405B;
}
.custom-text {
  color: #16213e !important;
}
</style>

<style lang="scss">
.custom-disabled.custom-control-primary {
  pointer-events: none;

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #C4C4C4 !important;
    background-color: #C4C4C4 !important;
  }
}

.custom-control-primary {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #7AB34C !important;
    background-color: #7AB34C !important;
  }
}
.custom-control-primary.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: none !important;
}
</style>
